import React, { Component } from 'react';

class Game1 extends Component {
  render() {
    return (
      <div>
        <div style={{ display: 'flex', maxWidth: 900 }}></div>
      </div>
    );
  }
}

export default Game1;
