import React from "react"
import ReactPlayer from "react-player"

function YoutubeApp() {
  return (
    <div>
      <ReactPlayer
        url="https://www.youtube.com/watch?v=b94MPIwNd9s"
      />
    </div>
  
  )
}

export default YoutubeApp
