import Attach from "../../../../content/components/attach";
import Quiz1 from "../../../../liftdane-quiz/src/App";
import Quiz2 from "../../../../liftdane-quiz2/src/App";
import ImgSlide from "../../../../content/components/imgSlide";
import * as React from 'react';
export default {
  Attach,
  Quiz1,
  Quiz2,
  ImgSlide,
  React
};